import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
		<path d="M1541 3415 c-164 -36 -330 -154 -416 -296 -37 -60 -89 -188 -81 -197
5 -4 26 41 26 56 0 4 16 38 36 76 54 101 132 176 189 181 6 0 9 6 7 13 -1 7 6
15 15 19 10 4 21 11 24 16 10 14 89 18 112 5 17 -9 19 -8 14 11 -3 11 -1 21 4
21 5 0 9 -7 9 -15 0 -8 4 -15 9 -15 6 0 7 -16 4 -35 -4 -23 -2 -35 6 -35 7 0
11 8 9 18 -2 14 6 18 45 20 38 3 47 7 47 22 0 21 25 32 89 35 24 2 47 8 51 14
10 17 80 17 91 0 5 -8 9 -9 9 -3 0 10 51 10 100 -1 20 -4 22 -3 10 5 -18 14
-9 11 52 -18 26 -13 50 -26 52 -31 3 -5 -12 -29 -34 -53 -30 -35 -45 -44 -67
-43 -18 2 -20 1 -5 -2 27 -5 27 -5 -2 -52 -40 -62 -75 -141 -81 -180 l-6 -36
16 35 c77 167 142 256 203 277 20 6 39 19 44 27 5 9 15 16 23 16 8 0 19 8 24
18 6 9 11 13 11 8 0 -5 6 -3 14 3 11 10 17 8 25 -6 6 -10 11 -13 11 -6 0 7 16
11 43 9 38 -1 42 -4 39 -26 -1 -13 -6 -27 -11 -30 -5 -3 -2 -11 6 -19 12 -11
14 -11 12 0 -4 34 2 39 51 39 41 0 50 3 50 18 0 10 14 26 30 35 17 10 30 13
30 8 0 -6 4 -11 9 -11 5 0 7 9 4 20 -3 11 0 20 6 20 6 0 11 -7 11 -15 0 -16
33 -17 56 -3 20 13 84 17 84 6 0 -7 15 -8 41 -4 22 4 38 3 35 -2 -3 -5 5 -7
17 -5 39 8 187 -70 187 -98 0 -5 -22 -41 -49 -81 -50 -74 -118 -232 -98 -225
6 2 15 19 20 38 5 19 12 36 15 39 4 3 15 23 26 45 41 90 148 200 182 188 6 -2
15 6 18 19 4 14 9 18 12 11 4 -8 14 -2 31 17 14 17 30 27 36 23 6 -4 24 -6 39
-5 15 1 28 -3 28 -9 0 -6 7 -3 16 6 20 19 35 5 27 -26 -4 -15 0 -17 23 -14 16
2 41 4 57 4 20 1 27 6 27 20 0 13 11 23 35 31 19 6 35 18 35 26 0 8 7 14 16
14 10 0 14 -6 10 -15 -6 -18 25 -21 54 -5 54 29 264 -7 312 -53 12 -13 42 -37
65 -55 71 -56 170 -212 188 -300 4 -18 9 -31 12 -28 9 9 -15 92 -46 159 -79
172 -256 313 -452 361 -182 44 -397 -4 -557 -126 -57 -44 -50 -45 -118 13 -47
39 -171 95 -251 113 -175 39 -368 2 -517 -99 l-36 -24 -46 31 c-140 94 -332
130 -503 93z m-46 -75 c-3 -5 -18 -10 -33 -10 -21 0 -24 2 -12 10 20 13 53 13
45 0z m205 1 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10
-4 10 -9z m596 -7 c-9 -8 -21 -12 -27 -8 -13 7 7 24 29 24 10 0 10 -4 -2 -16z
m924 1 c-7 -8 -16 -12 -21 -9 -13 8 -1 24 18 24 13 0 13 -3 3 -15z m-987 -20
c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z"/>
<path d="M1710 3264 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M3451 3264 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2531 3254 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3225 3230 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1285 3180 c3 -5 18 -10 32 -10 32 0 28 5 -10 14 -19 4 -26 3 -22 -4z"/>
<path d="M1385 3180 c-4 -6 5 -10 19 -10 14 0 26 5 26 10 0 6 -9 10 -19 10
-11 0 -23 -4 -26 -10z"/>
<path d="M2207 3184 c-4 -4 -4 -10 -1 -15 6 -10 34 0 34 12 0 10 -24 12 -33 3z"/>
<path d="M2753 3183 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2848 3183 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3130 3180 c-12 -8 -11 -10 7 -10 12 0 25 5 28 10 8 13 -15 13 -35 0z"/>
<path d="M3678 3183 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3768 3183 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2100 3176 c0 -10 28 -13 41 -5 10 6 7 9 -13 9 -16 0 -28 -2 -28 -4z"/>
<path d="M3033 3173 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3638 2910 c14 -15 27 -20 39 -16 15 6 16 9 3 15 -8 5 -26 12 -39 16
l-24 7 21 -22z"/>
<path d="M3765 2919 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M2720 2911 c0 -17 23 -23 51 -12 13 5 17 10 9 11 -8 0 -25 3 -37 6
-14 4 -23 2 -23 -5z"/>
<path d="M3796 2911 c-14 -5 -16 -10 -8 -15 16 -9 54 3 47 15 -7 10 -13 10
-39 0z"/>
<path d="M1270 2900 c0 -13 20 -13 40 0 12 8 9 10 -12 10 -16 0 -28 -4 -28
-10z"/>
<path d="M1405 2900 c3 -5 15 -10 26 -10 10 0 19 5 19 10 0 6 -12 10 -26 10
-14 0 -23 -4 -19 -10z"/>
<path d="M2087 2899 c8 -8 16 -8 30 0 16 9 14 10 -11 11 -22 0 -27 -3 -19 -11z"/>
<path d="M2225 2900 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10 -11
0 -17 -4 -14 -10z"/>
<path d="M3010 2900 c0 -13 20 -13 40 0 12 8 9 10 -12 10 -16 0 -28 -4 -28
-10z"/>
<path d="M3145 2900 c3 -5 12 -10 20 -10 8 0 17 5 20 10 4 6 -5 10 -20 10 -15
0 -24 -4 -20 -10z"/>
<path d="M4072 2860 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3735 2820 c-7 -12 2 -12 30 0 19 8 19 9 -2 9 -12 1 -25 -3 -28 -9z"/>
<path d="M1707 2795 c-3 -7 -23 -16 -44 -18 -33 -3 -35 -5 -15 -12 12 -4 22
-11 22 -14 0 -4 7 -8 15 -8 8 0 15 4 15 8 0 4 11 9 24 11 24 3 24 3 6 25 -14
18 -19 20 -23 8z"/>
<path d="M3447 2798 c-3 -11 -20 -18 -44 -20 -32 -3 -35 -5 -18 -13 11 -5 22
-12 25 -16 3 -5 23 -2 44 5 30 10 35 15 24 22 -8 5 -17 16 -20 23 -4 12 -5 12
-11 -1z"/>
<path d="M2488 2783 c-38 -4 -54 -23 -20 -23 23 0 42 -19 42 -42 0 -10 4 -18
10 -18 5 0 7 13 4 30 -6 28 -4 30 22 30 28 1 28 1 10 15 -11 8 -22 14 -25 13
-3 -1 -23 -3 -43 -5z"/>
<path d="M3728 2773 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1368 2733 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2180 2730 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M3105 2730 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M1692 2721 c4 -13 18 -16 19 -4 0 4 -5 9 -11 12 -7 2 -11 -2 -8 -8z"/>
<path d="M3440 2720 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1358 2683 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1395 2680 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M1323 2643 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2143 2643 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3128 2643 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3730 2640 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3761 2646 c12 -10 60 -16 54 -6 -3 5 -18 10 -32 10 -15 0 -24 -2
-22 -4z"/>
<path d="M3800 2599 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M1348 2593 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3720 2590 c0 -6 11 -9 25 -7 37 5 42 17 7 17 -18 0 -32 -5 -32 -10z"/>
<path d="M1835 2580 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1040 2571 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M2773 2560 c-1 -11 2 -18 8 -15 11 7 12 35 1 35 -4 0 -8 -9 -9 -20z"/>
<path d="M3692 2560 c-11 -18 -10 -19 13 -8 13 6 22 15 19 19 -8 14 -18 11
-32 -11z"/>
<path d="M3775 2560 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2621 2546 c-10 -12 -8 -13 8 -9 12 3 18 9 15 14 -7 12 -10 11 -23
-5z"/>
<path d="M3640 2556 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M3545 2540 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M2733 2521 c-3 -12 -10 -21 -16 -21 -8 0 -8 -3 0 -11 6 -6 16 -9 21
-5 14 9 22 45 10 52 -5 3 -12 -3 -15 -15z"/>
<path d="M3708 2533 c-11 -3 -18 -20 -23 -56 -9 -65 -8 -64 -42 -60 -20 3 -29
11 -30 24 -1 12 3 18 10 16 7 -3 11 -10 10 -16 -2 -6 6 -11 17 -11 17 0 19 3
11 19 -14 25 -36 32 -63 21 -13 -5 -18 -9 -12 -9 6 -1 9 -11 7 -25 -4 -17 -10
-22 -23 -19 -19 5 -22 33 -5 45 6 4 -2 8 -16 8 -15 0 -29 -7 -33 -15 -3 -8
-12 -15 -21 -15 -9 0 -18 7 -21 15 -4 8 -12 15 -20 15 -8 0 -11 -6 -8 -15 4
-8 12 -15 20 -15 8 0 14 -4 14 -10 0 -5 9 -10 21 -10 11 0 17 5 14 10 -3 6 1
10 9 10 19 0 22 -19 4 -22 -15 -2 -120 -4 -282 -6 -118 -2 -152 -11 -59 -17
98 -5 183 -18 178 -25 -3 -5 3 -11 14 -15 11 -3 22 -13 25 -21 7 -19 -20 -73
-40 -81 -7 -3 -14 -14 -14 -25 0 -10 -5 -16 -10 -13 -6 3 -17 -1 -26 -9 -28
-28 -39 -17 -12 12 l27 27 -24 27 c-12 15 -26 24 -29 21 -10 -10 -7 -21 10
-37 14 -15 14 -16 -3 -16 -10 0 -30 14 -45 32 -23 28 -29 31 -40 19 -8 -8 -8
-11 -1 -7 13 7 28 -28 18 -44 -4 -6 1 -7 11 -3 14 5 16 3 11 -11 -5 -14 -3
-17 8 -12 8 3 14 0 12 -6 -1 -7 16 -18 38 -26 72 -25 217 -19 308 13 23 8 51
15 61 15 11 0 16 3 13 7 -4 4 7 13 24 20 18 7 29 16 25 20 -3 4 -12 1 -18 -5
-21 -21 -28 -13 -23 23 5 43 -5 45 -28 5 -26 -43 -70 -42 -91 2 -9 17 -16 35
-16 39 0 17 33 39 58 39 18 0 23 3 14 8 -8 6 -2 9 17 9 17 0 28 -4 25 -9 -3
-4 0 -8 5 -8 6 0 11 5 11 10 0 6 7 10 16 10 8 0 12 5 9 10 -9 14 9 12 23 -2
10 -10 15 -10 22 2 16 24 12 30 -20 30 -16 0 -30 5 -30 10 0 9 17 14 48 13 6
-1 12 3 12 8 0 5 -17 9 -37 10 -21 0 -32 3 -25 6 6 2 12 9 12 15 0 15 32 37
55 38 l20 1 -20 9 c-22 9 -31 10 -57 3z m-248 -159 c0 -12 7 -15 25 -12 15 3
30 -1 39 -11 13 -14 13 -22 2 -56 -13 -38 -16 -40 -58 -41 l-43 -2 2 54 c1 29
-3 56 -8 60 -14 8 0 24 22 24 11 0 19 -7 19 -16z"/>
<path d="M3448 2335 c-11 -12 5 -53 22 -58 23 -6 55 27 47 48 -6 14 -57 22
-69 10z"/>
<path d="M1365 2516 c11 -8 25 -11 33 -8 11 4 11 6 0 13 -7 5 -22 9 -32 9 -19
0 -19 0 -1 -14z"/>
<path d="M1475 2521 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
<path d="M1871 2514 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2170 2520 c8 -5 24 -10 35 -10 17 0 17 2 5 10 -8 5 -24 10 -35 10
-17 0 -17 -2 -5 -10z"/>
<path d="M2290 2515 c0 -8 4 -15 9 -15 5 0 11 7 15 15 3 9 0 15 -9 15 -8 0
-15 -7 -15 -15z"/>
<path d="M2770 2523 c-1 -21 -12 -89 -15 -100 -8 -24 -26 -14 -19 11 4 19 1
25 -23 33 -15 5 -39 6 -53 2 -23 -6 -23 -7 8 -8 34 -1 50 -20 36 -42 -5 -9 -9
-8 -14 5 -6 19 -25 23 -25 4 0 -6 -8 -12 -17 -14 -10 -1 -18 2 -18 7 0 5 5 9
11 9 5 0 7 5 4 11 -5 7 -14 4 -27 -8 -21 -19 -22 -19 -292 -22 -66 0 -117 -3
-115 -6 3 -2 43 -7 90 -11 46 -4 85 -11 87 -15 2 -5 16 -6 30 -3 20 4 23 3 13
-4 -11 -8 -10 -11 9 -14 13 -1 28 -11 34 -20 14 -22 -3 -69 -29 -83 -12 -6
-19 -18 -17 -27 2 -10 -1 -18 -7 -18 -6 0 -12 5 -12 10 -1 6 -2 14 -3 18 -1
11 -53 72 -60 72 -12 0 -6 -24 11 -42 9 -10 14 -21 10 -24 -11 -12 -44 7 -55
31 -6 14 -18 25 -27 25 -17 0 -20 -16 -4 -25 5 -4 14 -18 20 -32 5 -14 14 -21
21 -17 6 4 8 3 5 -3 -11 -18 26 -40 78 -48 28 -4 61 -8 75 -10 29 -5 200 23
215 35 6 4 21 10 35 13 14 3 34 13 45 22 20 16 20 16 -3 10 -20 -6 -21 -4 -12
20 20 53 6 62 -25 15 -27 -41 -73 -39 -94 4 -16 35 -1 76 28 76 11 0 22 6 24
13 4 10 6 10 6 0 1 -16 31 -17 31 -2 0 8 5 8 15 -1 10 -9 15 -9 15 -1 0 6 7
11 15 11 9 0 12 6 8 18 -5 14 -3 15 8 5 18 -18 39 -16 39 2 0 9 -9 15 -25 15
-36 0 -31 17 8 26 l32 8 -37 6 -38 5 15 29 c8 15 15 30 15 32 0 8 -30 4 -30
-3z m-230 -148 c7 -8 16 -12 21 -9 5 3 18 0 30 -7 18 -9 20 -17 16 -46 -8 -49
-24 -63 -67 -63 l-38 0 1 60 c1 33 -1 60 -5 60 -5 0 -8 5 -8 10 0 15 36 12 50
-5z m-150 -170 c0 -8 -7 -15 -16 -15 -14 0 -14 3 -4 15 7 8 14 15 16 15 2 0 4
-7 4 -15z"/>
<path d="M2527 2333 c-4 -3 -7 -16 -7 -29 0 -29 34 -37 62 -14 19 15 20 20 9
34 -14 16 -52 22 -64 9z"/>
<path d="M3100 2520 c17 -11 47 -14 36 -3 -3 4 -17 8 -29 10 -17 2 -19 1 -7
-7z"/>
<path d="M3160 2526 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M3215 2521 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
<path d="M3575 2519 c-16 -5 -87 -11 -157 -12 -70 -1 -130 -6 -134 -10 -4 -4
55 -7 132 -7 120 0 145 3 179 20 41 21 31 25 -20 9z"/>
<path d="M3654 2515 c3 -8 1 -15 -5 -15 -6 0 -7 -4 -4 -10 11 -17 22 -11 27
15 4 17 1 25 -9 25 -9 0 -13 -6 -9 -15z"/>
<path d="M4035 2519 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M1819 2515 c-3 -2 -51 -6 -108 -7 -56 -1 -105 -6 -107 -10 -6 -9 207
-11 230 -2 9 3 16 10 16 15 0 9 -23 12 -31 4z"/>
<path d="M2638 2515 c-3 -3 -64 -6 -136 -8 -72 -1 -134 -6 -138 -10 -4 -4 58
-7 138 -7 117 0 148 3 158 15 10 12 10 15 -2 15 -8 0 -17 -2 -20 -5z"/>
<path d="M1550 2500 c0 -5 9 -10 21 -10 11 0 17 5 14 10 -3 6 -13 10 -21 10
-8 0 -14 -4 -14 -10z"/>
<path d="M3770 2502 c0 -5 16 -9 35 -10 20 -1 34 2 31 6 -6 11 -66 14 -66 4z"/>
<path d="M1865 2470 c-30 -8 -30 -8 8 -9 23 -1 36 3 32 9 -3 6 -7 9 -8 9 -1
-1 -15 -5 -32 -9z"/>
<path d="M1458 2463 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1705 2461 c-3 -5 4 -12 15 -16 11 -3 20 -10 20 -15 0 -9 -60 -20
-95 -18 -47 3 -258 -2 -254 -7 3 -2 41 -7 84 -10 44 -4 87 -9 95 -12 8 -3 20
-7 25 -8 25 -3 66 -26 71 -39 6 -17 -20 -71 -40 -83 -8 -4 -16 -17 -18 -28 -5
-18 -6 -18 -16 5 -15 33 -51 74 -59 67 -3 -4 2 -18 11 -32 16 -24 15 -25 -2
-25 -20 0 -52 29 -52 48 0 15 -26 16 -35 2 -3 -5 1 -10 10 -10 9 0 13 -4 10
-10 -3 -5 -2 -10 2 -10 5 0 13 -10 18 -22 6 -13 17 -22 24 -20 7 1 10 -2 7 -7
-8 -12 12 -27 47 -35 22 -5 27 -4 21 6 -6 10 -5 10 7 2 53 -41 251 -12 364 54
36 22 33 23 -10 6 -8 -3 -15 -1 -15 5 0 6 8 11 18 12 16 0 16 1 -1 7 -17 7
-16 9 3 30 11 12 16 22 9 22 -6 0 -26 -16 -43 -35 -43 -46 -78 -47 -100 -3 -9
17 -14 39 -11 49 7 23 68 43 103 35 15 -4 23 -3 17 1 -5 4 -10 17 -10 28 0 15
-7 20 -25 20 -15 0 -25 6 -25 15 0 8 -7 15 -15 15 -10 0 -14 -9 -13 -30 1 -16
-1 -22 -3 -12 -4 12 -16 17 -40 17 -19 0 -34 5 -34 11 0 7 12 8 33 4 29 -5 30
-5 14 9 -18 14 -18 14 0 19 17 4 17 5 -1 6 -11 0 -27 -6 -36 -14 -17 -15 -50
-11 -50 6 0 12 -17 12 -25 0z m12 -85 c-4 -11 4 -16 30 -18 36 -3 65 -30 49
-45 -4 -4 -12 0 -17 10 -14 24 -65 23 -72 -2 -2 -10 -2 -23 2 -28 10 -15 40
-23 46 -13 4 6 13 10 21 10 12 0 12 -4 4 -20 -8 -15 -20 -19 -55 -18 -25 1
-39 6 -32 10 7 4 8 8 2 8 -5 0 -10 27 -10 60 -1 52 1 60 18 60 13 0 17 -5 14
-14z m-141 -175 c-4 -5 -13 -8 -21 -5 -11 5 -12 9 -2 19 13 15 33 3 23 -14z"/>
<path d="M2278 2463 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2501 2447 c-19 -12 -29 -23 -23 -25 7 -2 19 3 27 11 15 16 59 12 49
-4 -3 -5 4 -9 15 -9 12 0 21 4 21 9 0 5 9 13 20 16 11 3 20 11 20 16 0 14 -17
11 -34 -6 -18 -18 -25 -19 -45 -1 -14 11 -21 10 -50 -7z"/>
<path d="M3198 2463 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1895 2441 c-3 -6 3 -9 14 -8 11 0 17 5 15 9 -7 11 -22 10 -29 -1z"/>
<path d="M1660 2430 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3400 2430 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3770 2418 c0 -22 16 -25 90 -18 l65 7 -65 1 c-37 1 -71 7 -78 14 -9
9 -12 8 -12 -4z"/>
<path d="M1351 2411 c-7 -5 -9 -11 -4 -14 5 -3 15 -2 22 2 7 5 9 11 4 14 -5 3
-15 2 -22 -2z"/>
<path d="M3085 2411 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
<path d="M1228 2403 c17 -2 47 -2 65 0 17 2 3 4 -33 4 -36 0 -50 -2 -32 -4z"/>
<path d="M2037 2403 c18 -2 50 -2 70 0 21 2 7 4 -32 4 -38 0 -55 -2 -38 -4z"/>
<path d="M2160 2400 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M2968 2403 c17 -2 47 -2 65 0 17 2 3 4 -33 4 -36 0 -50 -2 -32 -4z"/>
<path d="M1920 2346 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M1505 2330 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M1587 2333 c-12 -12 -7 -40 9 -54 14 -11 19 -10 35 10 15 18 17 27 8
37 -12 15 -41 19 -52 7z"/>
<path d="M1843 2324 c-7 -18 8 -49 23 -49 5 0 20 8 33 17 20 14 22 20 11 33
-17 20 -59 19 -67 -1z"/>
<path d="M2315 2330 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"/>
<path d="M2396 2332 c-8 -14 5 -50 21 -56 19 -8 45 19 41 42 -3 18 -52 30 -62
14z"/>
<path d="M2659 2324 c-13 -16 -4 -44 18 -51 19 -7 55 31 48 50 -9 21 -48 22
-66 1z"/>
<path d="M3245 2330 c-8 -13 5 -13 25 0 13 8 13 10 -2 10 -9 0 -20 -4 -23 -10z"/>
<path d="M3320 2325 c-9 -11 -8 -18 9 -36 l21 -23 19 20 c13 15 16 25 9 37
-11 21 -41 22 -58 2z"/>
<path d="M3581 2321 c-8 -14 -6 -23 9 -36 18 -16 20 -16 43 2 19 15 23 23 15
36 -12 23 -55 22 -67 -2z"/>
<path d="M1200 2313 c0 -5 13 -17 28 -27 l27 -19 -24 26 c-26 28 -31 32 -31
20z"/>
<path d="M2034 2295 c8 -8 18 -15 22 -15 3 0 1 7 -6 15 -7 8 -17 15 -22 15 -5
0 -3 -7 6 -15z"/>
<path d="M3869 2290 c-13 -10 -19 -19 -13 -20 6 0 19 9 29 20 23 26 14 26 -16
0z"/>
<path d="M3200 2262 c0 -7 -9 -12 -19 -12 -11 0 -23 -4 -26 -10 -3 -6 5 -10
18 -10 23 0 45 26 32 38 -3 3 -5 0 -5 -6z"/>
<path d="M3010 2256 c0 -2 14 -14 30 -26 17 -12 30 -19 30 -16 0 2 -13 14 -30
26 -16 12 -30 19 -30 16z"/>
<path d="M1425 2240 c-4 -6 6 -10 22 -10 22 0 25 2 13 10 -19 12 -27 12 -35 0z"/>
<path d="M2112 2234 c10 -10 20 -15 24 -12 3 4 -5 12 -18 18 -22 12 -22 12 -6
-6z"/>
<path d="M2236 2241 c-3 -5 3 -11 14 -14 21 -5 32 1 24 14 -7 12 -31 11 -38 0z"/>
<path d="M1389 2180 c139 -67 293 -85 434 -51 49 12 96 26 105 32 15 9 14 10
-3 4 -78 -25 -177 -37 -274 -32 -120 5 -129 7 -214 40 -95 38 -119 41 -48 7z"/>
<path d="M2192 2185 c97 -49 184 -69 308 -68 78 1 129 6 160 17 28 9 33 14 15
10 -16 -3 -81 -8 -144 -11 -129 -6 -189 3 -298 48 -87 35 -105 37 -41 4z"/>
<path d="M3110 2188 c19 -11 69 -32 110 -46 99 -32 260 -36 368 -8 94 24 88
31 -9 11 -88 -19 -223 -21 -299 -4 -30 6 -86 24 -125 40 -84 35 -91 36 -45 7z"/>
<path d="M1081 1278 c1 -59 3 -66 9 -40 4 18 6 42 3 53 -8 27 4 50 22 43 17
-7 21 -50 5 -56 -13 -4 7 -68 21 -68 5 0 3 12 -3 26 -9 20 -9 30 0 45 7 10 12
23 12 28 0 16 -31 41 -51 41 -17 0 -19 -7 -18 -72z"/>
<path d="M1217 1343 c-4 -3 -7 -35 -7 -70 0 -66 9 -80 47 -70 16 4 15 6 -4 12
-27 8 -33 55 -8 55 8 0 15 5 15 11 0 6 -7 9 -15 5 -11 -4 -15 1 -15 19 0 15 6
25 14 25 8 0 18 5 21 10 7 11 -38 14 -48 3z"/>
<path d="M1352 1327 c-28 -30 -29 -80 -2 -107 22 -22 45 -26 55 -10 4 6 -5 10
-20 10 -58 0 -59 100 0 110 25 5 27 7 12 13 -24 9 -20 10 -45 -16z"/>
<path d="M1483 1275 c0 -44 2 -63 4 -42 4 33 7 37 33 37 25 0 29 -4 33 -32 2
-18 4 -1 4 37 0 44 -2 59 -6 40 -3 -16 -10 -29 -14 -27 -4 1 -16 2 -26 2 -15
0 -21 8 -24 33 -2 17 -4 -4 -4 -48z"/>
<path d="M1786 1342 c-9 -14 -5 -131 5 -137 5 -4 9 10 9 31 0 30 2 34 14 25 8
-7 17 -23 21 -38 3 -14 9 -23 12 -20 3 3 1 20 -5 36 -7 19 -7 33 -1 36 16 11
10 54 -10 65 -23 12 -38 13 -45 2z m52 -34 c-5 -34 -38 -37 -38 -4 0 20 5 26
21 26 15 0 20 -5 17 -22z"/>
<path d="M1943 1337 c-43 -19 -41 -106 2 -125 34 -16 55 -5 54 27 -2 24 -2 24
-6 3 -7 -35 -54 -27 -64 11 -12 45 4 75 43 79 25 2 28 4 13 9 -11 3 -30 2 -42
-4z"/>
<path d="M2070 1275 c0 -43 3 -75 7 -71 8 8 7 135 -1 143 -3 4 -6 -29 -6 -72z"/>
<path d="M2341 1333 c-34 -29 -33 -95 3 -118 19 -13 29 -14 45 -5 21 11 29 50
11 50 -6 0 -10 -8 -8 -17 2 -12 -5 -19 -21 -21 -28 -4 -41 12 -41 54 0 37 16
54 50 54 20 0 22 2 10 10 -20 13 -25 13 -49 -7z"/>
<path d="M2551 1342 c-1 -7 4 -23 11 -35 7 -12 15 -42 19 -67 l6 -45 2 40 c1
22 8 51 16 65 8 14 14 32 14 40 0 8 -6 2 -13 -13 -17 -38 -25 -38 -41 -2 -7
17 -14 24 -14 17z"/>
<path d="M2701 1324 c-12 -15 -21 -37 -21 -49 0 -27 36 -75 56 -75 50 0 73 92
32 130 -29 27 -42 25 -67 -6z m71 -19 c16 -36 0 -79 -31 -83 -29 -5 -41 12
-41 59 0 42 5 49 37 49 15 0 27 -9 35 -25z"/>
<path d="M2858 1344 c-5 -4 -8 -29 -8 -56 0 -46 23 -88 49 -88 27 0 41 33 39
93 -1 51 -2 54 -5 17 -6 -78 -11 -92 -36 -88 -19 3 -23 10 -27 66 -3 35 -8 60
-12 56z"/>
<path d="M3010 1274 c0 -47 4 -73 10 -69 5 3 8 33 7 66 -2 33 -1 62 1 64 9 8
42 -16 42 -30 0 -8 -7 -18 -16 -23 -14 -8 -14 -13 5 -48 19 -34 21 -22 4 22
-2 7 0 15 6 19 29 17 -2 75 -40 75 -17 0 -19 -8 -19 -76z"/>
<path d="M3221 1340 c-1 -17 25 -120 33 -127 2 -3 10 13 17 36 7 23 15 41 19
41 4 0 13 -19 20 -42 l13 -43 19 59 c10 33 17 65 15 70 -2 6 -11 -11 -19 -39
l-14 -50 -13 40 c-19 57 -28 62 -36 18 -11 -61 -18 -61 -36 -3 -9 30 -17 48
-18 40z"/>
<path d="M3445 1325 c-27 -26 -31 -54 -14 -88 16 -30 30 -38 54 -31 48 14 62
87 23 124 -28 26 -34 25 -63 -5z m67 -20 c18 -41 0 -80 -37 -80 -28 0 -30 3
-33 39 -4 52 3 66 33 66 18 0 29 -8 37 -25z"/>
<path d="M3597 1343 c-11 -10 -8 -131 3 -138 6 -4 10 8 10 29 0 47 12 46 30
-1 7 -21 15 -33 17 -26 2 7 -1 22 -7 34 -9 15 -9 24 -1 32 18 18 13 55 -8 67
-23 12 -35 13 -44 3z m48 -38 c0 -11 -8 -21 -17 -23 -14 -3 -18 3 -18 23 0 20
4 26 18 23 9 -2 17 -12 17 -23z"/>
<path d="M3730 1276 c0 -76 0 -76 27 -76 14 0 23 3 19 8 -4 4 -13 7 -19 7 -7
0 -10 22 -8 61 1 36 -2 65 -8 69 -8 4 -11 -18 -11 -69z"/>
<path d="M3840 1275 c0 -81 4 -86 54 -64 46 21 46 107 0 128 -50 22 -54 17
-54 -64z m65 0 c0 -47 -2 -50 -27 -53 -27 -3 -27 -3 -25 53 2 53 3 56 27 53
23 -3 25 -7 25 -53z"/>
<path d="M1666 1330 c-19 -48 -36 -105 -35 -119 0 -11 3 -9 9 7 13 30 55 30
63 0 4 -12 8 -20 11 -17 3 2 -6 36 -19 74 -13 42 -25 64 -29 55z m14 -45 c0
-8 0 -18 1 -22 0 -4 -4 -9 -10 -11 -6 -2 -11 8 -11 22 0 14 5 26 10 26 6 0 10
-7 10 -15z"/>
<path d="M2235 1300 c6 -48 -9 -60 -29 -22 -8 16 -22 34 -30 41 -14 12 -16 6
-14 -55 l1 -69 6 55 6 55 34 -47 c19 -27 36 -48 38 -48 2 0 3 29 3 65 0 37 -4
65 -10 65 -6 0 -8 -16 -5 -40z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
